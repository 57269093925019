import React from "react"
import Layout from "../components/layout"
import Home from "../components/Home"
import { withContext } from "../i18n"
import { withNamespaces } from "react-i18next"
import "./index.scss"

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
)

export default withContext(withNamespaces("Home")(IndexPage))
