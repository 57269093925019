import React, { Component } from "react"
import PropTypes from "prop-types"
import { withNamespaces } from "react-i18next"
import "./home.scss"

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  downloadURL = () => {
    const userAgent = window.navigator.userAgent
    return userAgent.match(/Android/i)
      ? "https://play.google.com/store/apps/details?id=com.nearby.share"
      : userAgent.match(/iPhone|iPad|iPod/i)
      ? "https://apps.apple.com/us/app/elapp-nearby-sharing/id1543464747"
      : userAgent.indexOf("Win") != -1
      ? "https://cdn.criptext.com/elapp/windows/elapp-latest.exe"
      : userAgent.indexOf("Mac") != -1
      ? "https://cdn.criptext.com/elapp/mac/elapp-latest.dmg"
      : userAgent.indexOf("Linux") != -1
      ? "https://cdn.criptext.com/elapp/linux/elapp-latest.AppImage"
      : "https://cdn.criptext.com/elapp/linux/elapp-latest.AppImage"
  }

  render() {
    const { t } = this.props
    return (
      <div className="main-container">
        <div className="custom-shape-divider-top-1608239728 desktop">
          <svg
            viewBox="0 0 1440 990"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="desktop"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="FONDO-ONDAS-Copy"
                transform="translate(-579.130522, -808.000000)"
              >
                <path
                  d="M51.8947045,954.23311 C51.8947045,1408.65168 417.140769,1777.03128 867.680311,1777.03128 C1056.83336,1777.03128 1242.25015,1709.67029 1387.93992,1588.00463 C1531.19621,1468.37195 1608.02624,1267.23731 1821.71082,1267.23731 C2010.49516,1267.23731 2163.5361,1421.59487 2163.5361,1612.00679 L2163.5361,123.812827 L51.8947045,123.812827 L51.8947045,954.23311 Z"
                  id="Fill-1"
                  fill="#321A7F"
                  transform="translate(1107.715402, 950.422052) rotate(-3.000000) translate(-1107.715402, -950.422052) "
                />
                <path
                  d="M101.125888,841.257406 C101.125888,1246.36609 449.332619,1574.77207 878.869999,1574.77207 C1059.20557,1574.77207 1235.97904,1514.72057 1374.8774,1406.25709 C1511.45572,1299.60599 1584.70427,1120.29688 1788.42782,1120.29688 C1968.41187,1120.29688 2114.31872,1257.90479 2114.31872,1427.65472 L2114.31872,100.947716 L101.125888,100.947716 L101.125888,841.257406 Z"
                  id="Fill-1-Copy"
                  fill="#3A2383"
                  transform="translate(1107.722303, 837.859894) rotate(-3.000000) translate(-1107.722303, -837.859894) "
                />
                <path
                  d="M60.5472588,752.086853 C60.5472588,1127.5444 408.753989,1431.91334 838.29137,1431.91334 C1018.62694,1431.91334 1195.40041,1376.25719 1334.29877,1275.73249 C1470.87709,1176.88751 1544.12564,1010.70258 1747.84919,1010.70258 C1927.83324,1010.70258 2073.74009,1138.23854 2073.74009,1295.56396 L2073.74009,65.9626842 L60.5472588,65.9626842 L60.5472588,752.086853 Z"
                  id="Fill-1-Copy-2"
                  fill="#422D85"
                  transform="translate(1067.143673, 748.938014) rotate(-3.000000) translate(-1067.143673, -748.938014) "
                />
                <path
                  d="M31.0136872,658.111631 C31.0136872,989.463107 379.220418,1258.07696 808.757798,1258.07696 C989.093369,1258.07696 1165.86684,1208.9589 1304.7652,1120.24312 C1441.34351,1033.00974 1514.59207,886.347031 1718.31562,886.347031 C1898.29967,886.347031 2044.20652,998.90098 2044.20652,1137.74493 L2044.20652,52.5884262 L31.0136872,52.5884262 L31.0136872,658.111631 Z"
                  id="Fill-1-Copy-3"
                  fill="#483388"
                  transform="translate(1037.610101, 655.332696) rotate(-3.000000) translate(-1037.610101, -655.332696) "
                />
              </g>
            </g>
          </svg>
          <svg
            viewBox="0 0 320 546"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="mobile"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="bg_ondasmobile"
                transform="translate(-22.269836, -17.555502)"
              >
                <path
                  d="M13.255349,531.894771 C40.5641206,548.313628 73.4136799,558.6175 107.515477,558.6175 C149.83947,558.6175 191.327454,543.545125 223.926309,516.321791 C255.980659,489.553347 273.171784,444.548411 320.984837,444.548411 C327.009999,444.548411 332.872446,445.251095 338.496013,446.579646 C339.354867,430.191744 343.901318,343.440282 352.135367,186.325261 L32.244484,169.56049 C23.2190502,341.776026 16.8893385,462.55412 13.255349,531.894771 Z"
                  id="Fill-1"
                  fill="#321A7F"
                  transform="translate(182.695358, 364.088995) rotate(-3.000000) translate(-182.695358, -364.088995) "
                />
                <path
                  d="M23.8264354,303.063715 C20.7375831,362.002528 18.795213,399.065158 14.2279245,486.214213 C41.7298257,503.38189 74.7038978,513.37876 110.153722,513.37876 C150.504758,513.37876 190.058755,499.941919 221.137996,475.672644 C251.698113,451.808898 268.087864,411.687539 313.672084,411.687539 C322.446118,411.687539 330.858224,413.149045 338.649638,415.828018 C342.724679,338.071615 343.898057,315.682218 347.253089,251.664409 L350.619706,186.340288 L30.8219995,169.580401 L23.8264354,303.063715 Z"
                  id="Fill-1-Copy"
                  fill="#3A2383"
                  transform="translate(182.423815, 341.479580) rotate(-3.000000) translate(-182.423815, -341.479580) "
                />
                <path
                  d="M14.456225,460.902628 C39.9102186,473.649485 69.4012683,480.936333 100.839442,480.936333 C141.190478,480.936333 180.744475,468.482976 211.823716,445.990043 C242.383833,423.872958 258.773584,386.688203 304.357804,386.688203 C316.673707,386.688203 328.276502,389.357051 338.450529,394.069213 C339.823105,367.785833 343.744459,292.961956 350.214588,169.597583 L30.6035457,152.793393 C21.9031804,318.806252 16.5207402,421.509331 14.456225,460.902628 Z"
                  id="Fill-1-Copy-2"
                  fill="#422D85"
                  transform="translate(182.335407, 316.864863) rotate(-3.000000) translate(-182.335407, -316.864863) "
                />
                <path
                  d="M11.4598756,426.951797 C35.1911655,436.30922 62.0745447,441.587329 90.5560245,441.587329 C130.90706,441.587329 170.461057,430.596902 201.540298,410.746279 C232.100416,391.227351 248.490166,358.410795 294.074386,358.410795 C309.52815,358.410795 323.204618,362.113815 334.998964,368.439993 C336.285878,343.884208 342.482212,229.81594 353.587966,26.2351893 L33.3735005,9.45346032 C20.0298515,264.065451 12.7253099,403.231563 11.4598756,426.951797 Z"
                  id="Fill-1-Copy-3"
                  fill="#483388"
                  transform="translate(182.523921, 225.520395) rotate(-3.000000) translate(-182.523921, -225.520395) "
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="container-left">
          <div className="custom-shape-divider-top-1608239728 mobile">
            <svg
              viewBox="0 0 1440 990"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="desktop"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="FONDO-ONDAS-Copy"
                  transform="translate(-579.130522, -808.000000)"
                >
                  <path
                    d="M51.8947045,954.23311 C51.8947045,1408.65168 417.140769,1777.03128 867.680311,1777.03128 C1056.83336,1777.03128 1242.25015,1709.67029 1387.93992,1588.00463 C1531.19621,1468.37195 1608.02624,1267.23731 1821.71082,1267.23731 C2010.49516,1267.23731 2163.5361,1421.59487 2163.5361,1612.00679 L2163.5361,123.812827 L51.8947045,123.812827 L51.8947045,954.23311 Z"
                    id="Fill-1"
                    fill="#321A7F"
                    transform="translate(1107.715402, 950.422052) rotate(-3.000000) translate(-1107.715402, -950.422052) "
                  />
                  <path
                    d="M101.125888,841.257406 C101.125888,1246.36609 449.332619,1574.77207 878.869999,1574.77207 C1059.20557,1574.77207 1235.97904,1514.72057 1374.8774,1406.25709 C1511.45572,1299.60599 1584.70427,1120.29688 1788.42782,1120.29688 C1968.41187,1120.29688 2114.31872,1257.90479 2114.31872,1427.65472 L2114.31872,100.947716 L101.125888,100.947716 L101.125888,841.257406 Z"
                    id="Fill-1-Copy"
                    fill="#3A2383"
                    transform="translate(1107.722303, 837.859894) rotate(-3.000000) translate(-1107.722303, -837.859894) "
                  />
                  <path
                    d="M60.5472588,752.086853 C60.5472588,1127.5444 408.753989,1431.91334 838.29137,1431.91334 C1018.62694,1431.91334 1195.40041,1376.25719 1334.29877,1275.73249 C1470.87709,1176.88751 1544.12564,1010.70258 1747.84919,1010.70258 C1927.83324,1010.70258 2073.74009,1138.23854 2073.74009,1295.56396 L2073.74009,65.9626842 L60.5472588,65.9626842 L60.5472588,752.086853 Z"
                    id="Fill-1-Copy-2"
                    fill="#422D85"
                    transform="translate(1067.143673, 748.938014) rotate(-3.000000) translate(-1067.143673, -748.938014) "
                  />
                  <path
                    d="M31.0136872,658.111631 C31.0136872,989.463107 379.220418,1258.07696 808.757798,1258.07696 C989.093369,1258.07696 1165.86684,1208.9589 1304.7652,1120.24312 C1441.34351,1033.00974 1514.59207,886.347031 1718.31562,886.347031 C1898.29967,886.347031 2044.20652,998.90098 2044.20652,1137.74493 L2044.20652,52.5884262 L31.0136872,52.5884262 L31.0136872,658.111631 Z"
                    id="Fill-1-Copy-3"
                    fill="#483388"
                    transform="translate(1037.610101, 655.332696) rotate(-3.000000) translate(-1037.610101, -655.332696) "
                  />
                </g>
              </g>
            </svg>
            <svg
              viewBox="0 0 320 546"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="mobile"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="bg_ondasmobile"
                  transform="translate(-22.269836, -17.555502)"
                >
                  <path
                    d="M13.255349,531.894771 C40.5641206,548.313628 73.4136799,558.6175 107.515477,558.6175 C149.83947,558.6175 191.327454,543.545125 223.926309,516.321791 C255.980659,489.553347 273.171784,444.548411 320.984837,444.548411 C327.009999,444.548411 332.872446,445.251095 338.496013,446.579646 C339.354867,430.191744 343.901318,343.440282 352.135367,186.325261 L32.244484,169.56049 C23.2190502,341.776026 16.8893385,462.55412 13.255349,531.894771 Z"
                    id="Fill-1"
                    fill="#321A7F"
                    transform="translate(182.695358, 364.088995) rotate(-3.000000) translate(-182.695358, -364.088995) "
                  />
                  <path
                    d="M23.8264354,303.063715 C20.7375831,362.002528 18.795213,399.065158 14.2279245,486.214213 C41.7298257,503.38189 74.7038978,513.37876 110.153722,513.37876 C150.504758,513.37876 190.058755,499.941919 221.137996,475.672644 C251.698113,451.808898 268.087864,411.687539 313.672084,411.687539 C322.446118,411.687539 330.858224,413.149045 338.649638,415.828018 C342.724679,338.071615 343.898057,315.682218 347.253089,251.664409 L350.619706,186.340288 L30.8219995,169.580401 L23.8264354,303.063715 Z"
                    id="Fill-1-Copy"
                    fill="#3A2383"
                    transform="translate(182.423815, 341.479580) rotate(-3.000000) translate(-182.423815, -341.479580) "
                  />
                  <path
                    d="M14.456225,460.902628 C39.9102186,473.649485 69.4012683,480.936333 100.839442,480.936333 C141.190478,480.936333 180.744475,468.482976 211.823716,445.990043 C242.383833,423.872958 258.773584,386.688203 304.357804,386.688203 C316.673707,386.688203 328.276502,389.357051 338.450529,394.069213 C339.823105,367.785833 343.744459,292.961956 350.214588,169.597583 L30.6035457,152.793393 C21.9031804,318.806252 16.5207402,421.509331 14.456225,460.902628 Z"
                    id="Fill-1-Copy-2"
                    fill="#422D85"
                    transform="translate(182.335407, 316.864863) rotate(-3.000000) translate(-182.335407, -316.864863) "
                  />
                  <path
                    d="M11.4598756,426.951797 C35.1911655,436.30922 62.0745447,441.587329 90.5560245,441.587329 C130.90706,441.587329 170.461057,430.596902 201.540298,410.746279 C232.100416,391.227351 248.490166,358.410795 294.074386,358.410795 C309.52815,358.410795 323.204618,362.113815 334.998964,368.439993 C336.285878,343.884208 342.482212,229.81594 353.587966,26.2351893 L33.3735005,9.45346032 C20.0298515,264.065451 12.7253099,403.231563 11.4598756,426.951797 Z"
                    id="Fill-1-Copy-3"
                    fill="#483388"
                    transform="translate(182.523921, 225.520395) rotate(-3.000000) translate(-182.523921, -225.520395) "
                  />
                </g>
              </g>
            </svg>
          </div>
          <img src={"img_logo.svg"} className="logo" />
          <div className="bubble-container">
            <div className="bubble desktop">
              <img src={t("bubble1")} />
              <div>
                {t("pasame")}{" "}
                <span>
                  elapp<span>.</span>
                </span>
              </div>
            </div>
            <div className="bubble desktop">
              <img src={t("bubble2")} />
              <div>{t("que_es")}</div>
            </div>
            <div className="bubble desktop">
              <img src={t("bubble3")} />
              <div>
                <span>elapp</span> {t("es_el")}
              </div>
            </div>
            <a href={this.downloadURL()}>
              <div className="btnDownload">
                <img src={"ic-devices.svg"} />
                {t("download")}
              </div>
            </a>
          </div>
        </div>
        <div className="container-right">
          <img src={t("phone")} className="phone" />
          <div className="text">
            {t("text")}
            <br />
            <div className="social-icons">
              <a href="https://instagram.com/elapphq" target="_blank">
                <img src={"ic-insta.svg"} />
              </a>
              <a href="https://twitter.com/elappHQ" target="_blank">
                <img src={"ic-twitter.svg"} />
              </a>
              <a href="https://facebook.com/elappHQ" target="_blank">
                <img src={"ic-facebook.svg"} />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withNamespaces("Home")(Home)
